<template>
    <div class="page">
        <div class="query-form p_b1">
            <el-select v-model="searchForm.blackState" placeholder="请选择拉黑状态" size="small" clearable style="width: 12%"
                       class="m_r1">
                <el-option
                        v-for="item in blackStateOpt"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
            <el-date-picker size="small"
                            v-model="blackTime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="拉黑开始时间"
                            end-placeholder="拉黑结束时间" style="width: 28%">
            </el-date-picker>
            <el-select v-model="searchForm.cardTypeId" placeholder="证件类型" size="small" class="m_l1 m_r1"
                       style="width: 15%" clearable>
                <el-option
                        v-for="item in cardTypeOpt"
                        :key="item.id"
                        :label="item.cardTypeName"
                        :value="item.id">
                </el-option>
            </el-select>
            <el-input size="small" v-model="searchForm.keyWords" placeholder="请输入证件号/姓名/订单编号/票号" maxlength="50" class="m_r1"
                      style="width: 25%" clearable></el-input>
            <el-button type="primary" @click="queryData(1)" size="small" icon="el-icon-search">查询
            </el-button>
            <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
        </div>
        <div class="bg-white">
            <el-table :data="list"
                      v-loading="loading"
                      size="small"
                      height="calc(100vh - 280px)" class="table">
                <el-table-column prop="ticketingVisitorUser" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingCardType" label="证件类型" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVisitorCard" label="证件号" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingValidationCode" label="票号" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="makeDate" label="参观日期" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="storehouseTime" label="入馆时段" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column prop="cumulativeNums" label="累计爽约次数" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="breakAppointmentTime" label="爽约时间" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="blackRule" label="拉黑触发方式" width="140" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{scope.row.blackRule == 0 ? '累计': (scope.row.blackRule == 90 ? '近3月': (scope.row.blackRule == 180 ? '近半年' : '近1年'))}}
                    </template>
                </el-table-column>
                <el-table-column prop="blackNums" label="拉黑触发次数（次）" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="blackDay" label="拉黑禁止时长（天）" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="blackState" label="拉黑状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{scope.row.blackState == 0 ? '否':'是'}}
                    </template>
                </el-table-column>
                <el-table-column prop="pullBlackTime" label="拉黑时间" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="100" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('noShowRecord:view')" type="text" size="mini"
                                   @click="view(scope.row)">详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--详情-->
        <NoShowDetails ref="noShowDetails"></NoShowDetails>
    </div>
</template>

<script>
    import NoShowDetails from './noShowDetails'

    export default {
        components: {NoShowDetails},
        data() {
            return {
                searchForm: {
                    blackState: null,
                    cardTypeId: null,
                    keyWords: '',
                    current: 1,
                    size: 10,
                    blackStartTime: '',
                    blackEndTime: '',
                },
                blackTime: null,
                blackStateOpt: [
                    {
                        label: '是',
                        value: '1'
                    },
                    {
                        label: '否',
                        value: '0'
                    }
                ],
                cardTypeOpt: [],
                list: [],
                total: 0,
                loading: false,
            }
        },
        mounted() {
            this.getCardType()
            this.queryData(1)
        },
        methods: {
            // 获取数据
            queryData(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                let param = JSON.parse(JSON.stringify(this.searchForm))
                if(this.blackTime) {
                    param.blackStartTime = this.blackTime[0]
                    param.blackEndTime = this.blackTime[1]
                }
                this.loading = true
                this.$axios(this.api.order.sysGetTicketingBreakAppointment, param, 'post').then((res) => {
                    if (res.status) {
                        this.list = res.data.records
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 重置
            resetSearch() {
                this.searchForm = {
                    blackState: null,
                    ticketingCardTypeId: null,
                    keyWords: '',
                    current: 1,
                    size: 10,
                }
                this.blackTime = null
                this.queryData(1)
            },
            // 详情
            view(row) {
                this.$refs.noShowDetails.init(row)
            },
            // 获取证件类型
            getCardType() {
                this.$axios(this.api.registrationMethod.list, {
                    current: 1,
                    size: 999,
                    cardName: '',
                }, 'get').then((res) => {
                    if (res.status) {
                        this.cardTypeOpt = res.data.records;
                    }
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.queryData()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.queryData()
            },
        }
    }
</script>

<style scoped>

</style>
